import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './Component/Pages/Views/Navbar';
import Home from './Component/Pages/Home';
import Introducation from './Component/Pages/Views/Introducation';
import Vision from "./Component/Pages/Views/vision"
import Chairman from './Component/Pages/Views/Chairman';
import Director from './Component/Pages/Views/Director';
import Principal from './Component/Pages/Views/Principal';
import Rulesregulations from './Component/Pages/Views/Rules-regulations'
import Grievance from './Component/Pages/Views/Grievance'
import Facilities from './Component/Pages/Facilities'
import Application from './Component/Pages/Views/Application'
import Gallery from './Component/Pages/Gallery';
import Videogallery from './Component/Pages/Views/videogallery';
import Contact from './Component/Pages/Contact';
import Dpharmacy from './Component/Pages/Views/D-pharmacy';
import Bpharmacy from './Component/Pages/Views/B-pharmacy';
import Mpharmacy from './Component/Pages/Views/M-pharmacy';
import Alumni from './Component/Pages/Views/Alumni'
import Header from './Component/Pages/Views/Header'
import Footer from './Component/Pages/Views/Footer'




function App() {
  return (
    <Router>
      <div>
        <Header />
        <Navbar />
        <Routes>
          <Route path="/home" element={<Home />} />
          <Route path="/introducation" element={<Introducation/>} />
          <Route path="/vision" element={<Vision />} />
          <Route path="/chairman" element={<Chairman />} />
          <Route path="/director" element={<Director />} />
          <Route path="/principal" element={<Principal />} />
          <Route path="/chairman" element={<Chairman />} />
          <Route path="/Rules-regulations" element={<Rulesregulations />} />
          <Route path="/grievance" element={<Grievance />} />
          
          <Route path="/facilities" element={<Facilities />} />
          <Route path="D-pharmacy" element={<Dpharmacy />} />
          <Route path="B-pharmacy" element={<Bpharmacy />} />
          <Route path="M-pharmacy" element={<Mpharmacy />} />
          <Route path="application" element={<Application />} />
          <Route path="alumni" element={<Alumni />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/videogallery" element={<Videogallery />} />
          <Route path="/" element={<Home />} />
          
        </Routes>
        <Footer />
      </div>
    </Router>
    
  );
}

export default App;
