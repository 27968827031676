import React from 'react';

import 'bootstrap/dist/css/bootstrap.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faXTwitter, faSquareInstagram,faYoutube } from '@fortawesome/free-brands-svg-icons';
import logoimg from '../../Assets/img/cover/logo.png'
import rgipimg from '../../Assets/img/cover/rgip.jpg'



function Header() {
  return (
    <>
      {/* Header Top */}
      <div className="header-top">
        {/* <div className="container"> */}
          <div className="row align-items-center">
            {/* <div className="col-sm-10 col-lg-10 ">        */}
              <div className="col-md-2 text-center">
                <img src={rgipimg} alt="Logo" className="img-fluid retro-logo" />
              </div>
              <div className="col-md-8 retro-title">
                <p>RAJIV GANDHI INSTITUTE OF PHARMACY</p>
              </div>
                {/* <div className="header-top-item">
                          {/* <img src={logoimg} alt="logo"/> */}
                          {/* <img src={rgipimg} className='text-2xs' alt="logo"/>
                        </div> */} 
            {/* </div> */}
            <div className="col-md-8 col-lg-2">
              <div className="header-top-item">
                <div className="header-top-right">
                  <ul>
                    <li>
                      <a href="https://www.facebook.com/rgcnjammuofficial" target="_blank" rel="noopener noreferrer">
                       <FontAwesomeIcon icon={faFacebookF} style={{color: "#ffffff",}} />
                      </a>
                    </li>
                    <li>
                      <a href="https://twitter.com/JammuRgcn" target="_blank" rel="noopener noreferrer">
                      <FontAwesomeIcon icon={faXTwitter} style={{color: "#ffffff",}} />
                      </a>
                    </li>
                    <li>
                      <a href="https://www.instagram.com/rgcnjammu" target="_blank" rel="noopener noreferrer">
                      <FontAwesomeIcon icon={faSquareInstagram} style={{color: "#ffffff",}} />
                      </a>
                    </li>
                    <li>
                      <a href="https://www.youtube.com/channel/UCxVIoBj_3ILZQkyB1z8uxtQ" target="_blank" rel="noopener noreferrer">
                      <FontAwesomeIcon icon={faYoutube} style={{color: "#ffffff",}} />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      {/* </div> */}
      {/* End Header Top */}

      {/*  */}
    </>
  );
}

export default Header;
