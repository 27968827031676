import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../Assets/Style.css';

import '../Assets/css/animate.css';
import '../Assets/css/bootstrap.min.css';
import '../Assets/css/home-notification.css';
// import '../Assets/css/icofont.css';
import '../Assets/css/magnific-popup.css';
import '../Assets/css/meanmenu.css';
import '../Assets/css/owl.carousel.min.css';
import '../Assets/css/owl.theme.default.min.css';
import '../Assets/css/responsive.css';
import '../Assets/css/slick.min.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocationDot, faEnvelope,faSquarePhone} from '@fortawesome/free-solid-svg-icons';
import contactimg from '../Assets/img/slider/banner1.jpg'

const Contact = () => {
    return (
        <div>
            {/* Page Title */}
            <div className="page-title-area page-title-four">
        <div className="d-table">
          <div className="d-table-cell">
            <div className="page-title-item">
              <h2>CONTACT US</h2>
              <ul>
                <li>
                  <a href="Default.aspx">Home</a>
                </li>
                <li>
                  <i className="icofont-simple-right"></i>
                </li>
                <li>Contact Us</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
            {/* End Page Title */}

            {/* Location */}
            <div className="location-area">
                <div className="container">
                    <div className="row location-wrap">
                        <div className="col-sm-6 col-lg-4">
                            <div className="location-item">
                            <i className="icofont-ui-call"> <FontAwesomeIcon icon={faLocationDot} /></i>
                                <h3>Location</h3>
                                <p>KAWA Tower, NH BYE PASS ROAD, NARWAL, JAMMU. J&K</p>
                            </div>
                        </div>
                        <div className="col-sm-6 col-lg-4">
                            <div className="location-item">
                                <i className="icofont-ui-message"><FontAwesomeIcon icon={faEnvelope} /></i>
                                <h3>Email</h3>
                                <ul>
                                    <li>rgipjammu@gmail.com</li>
                                 
                                </ul>
                            </div>
                        </div>
                        <div className="col-sm-6 offset-sm-3 offset-lg-0 col-lg-4">
                            <div className="location-item">
                                <i className="icofont-ui-call"><FontAwesomeIcon icon={faSquarePhone} /></i>
                                <h3>Phone</h3>
                                <ul>
                                    <li>+91 7006153585</li>
                                    
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* End Location */}

            {/* Drop */}
            <section className="drop-area pt-100">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-7 p-0">
                            <div className="drop-item drop-img">
                                <div className="drop-left">
                                    <h2>Drop your message for any info or question.</h2>
                                    <div className="message">{'{'}{'{'}mesg{'}'}{'}'}</div><br />
                                    <form action="/contact" method="POST" id="form">
                                        <div className="row">
                                            <div className="col-sm-6 col-lg-6">
                                                <div className="form-group">
                                                    <input type="text" name="name" id="name" className="form-control" required
                                                        data-error="Please enter your name" placeholder="Name" />
                                                    <p id="namemessage" className="message"></p>
                                                    <div className="help-block with-errors"></div>
                                                </div>
                                            </div>

                                            <div className="col-sm-6 col-lg-6">
                                                <div className="form-group">
                                                    <input type="email" name="email" id="email" className="form-control" required
                                                        data-error="Please enter your email" placeholder="Email" />
                                                    <p id="emailmessage" className="message"></p>
                                                    <div className="help-block with-errors"></div>
                                                </div>
                                            </div>

                                            <div className="col-sm-6 col-lg-6">
                                                <div className="form-group">
                                                    <input type="text" name="phone_number" id="phonenumber" required
                                                        data-error="Please enter your number" className="form-control"
                                                        placeholder="Phone" />
                                                    <p id="phonemessage" className="message"></p>

                                                    <div className="help-block with-errors"></div>
                                                </div>
                                            </div>

                                            <div className="col-sm-6 col-lg-6">
                                                <div className="form-group">
                                                    <input type="text" name="msg_subject" id="msg_subject" className="form-control"
                                                        required data-error="Please enter your subject" placeholder="Subject" />
                                                    <div className="help-block with-errors"></div>
                                                    <p id="subjectmessage" className="message"></p>
                                                </div>
                                            </div>

                                            <div className="col-md-12 col-lg-12">
                                                <div className="form-group">
                                                    <textarea name="message" className="form-control" id="message" cols="30" rows="5"
                                                        required data-error="Write your message"
                                                        placeholder="Your Message"></textarea>
                                                    <p id="textmessage" className="message"></p>
                                                    <div className="help-block with-errors"></div>
                                                </div>
                                            </div>

                                            <div className="col-md-12 col-lg-12">
                                                <button type="submit" className="drop-btn" id="submit">
                                                    Send
                                                </button>
                                                <div id="msgSubmit" className="h3 text-center hidden"></div>
                                                <div className="clearfix"></div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-5 p-0">
                            <div className="contact-img">
                                <img src={contactimg} alt="Contact" />
                                {/* <div className="speciality-emergency">
                                    <div className="speciality-icon">
                                        <i className="icofont-ui-call"></i>
                                    </div>
                                    <h3>Emergency Call</h3>
                                    <p>+91 9622271116</p>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* End Drop */}

            {/* Map */}
            <div className="contact-map-area">
                <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3350.596886167853!2d74.80173181470957!3d32.8823835859135!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x391e8360fffffffd%3A0xc20de5533e10912a!2sRajiv%20Gandhi%20College%20of%20Nursing!5e0!3m2!1sen!2sin!4v1593413934791!5m2!1sen!2sin"
                    width="1340" height="450" frameBorder="0" style={{ border: 0 }} allowFullScreen="" aria-hidden="false"
                    tabIndex="0"></iframe>
            </div>
        </div>
    );
}

export default Contact;
