import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import '../../Assets/Style.css'; // Import your custom CSS for styling if needed

const SignupForm = () => {
    const [formData, setFormData] = useState({
        name: '',
        fname: '',
        address: '',
        pincode: '',
        city: '',
        state: '',
        number: '',
        mobile: '',
        email: '',
        courses: '',
        session: '',
        nationality: '',
        gender: '',
        organization: '',
        designation: '',
        development: '',
        achievements: '',
        message: ''
    });
    const [mesg, setMesg] = useState('');
    const [errmesg, setErrmesg] = useState('');
    const [messages, setMessages] = useState({});

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        emailjs.sendForm('YOUR_SERVICE_ID', 'YOUR_TEMPLATE_ID', e.target, 'YOUR_USER_ID')
            .then((result) => {
                setMesg('Registration successful!');
                setErrmesg('');
                console.log(result.text);
            }, (error) => {
                setErrmesg('Registration failed. Please try again.');
                setMesg('');
                console.log(error.text);
            });
    };

    return (
        <div className="signup-area">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-6 pl-0">
                        <div className="login-left">
                            <img src="assets/img/login-bg.jpg" alt="Login" />
                        </div>
                    </div>
                    <div className="col-lg-6 pb-100">
                        <div className="signup-item">
                            <div className="signup-head">
                                <h2>ALUMNI MEET REGISTRATION FORM</h2>
                            </div>
                            <div className="signup-form">
                                <p className="successmessage">{mesg}</p>
                                <p className="message">{errmesg}</p>
                                <br />
                                <form onSubmit={handleSubmit} id="almuniform">
                                    <div className="row">
                                        {[
                                            { name: 'name', placeholder: 'Name (Mr/Mrs/Ms)' },
                                            { name: 'fname', placeholder: "Father's Name" },
                                            { name: 'address', placeholder: 'Postal Address' },
                                            { name: 'pincode', placeholder: 'Pin Code', type: 'number' },
                                            { name: 'city', placeholder: 'City' },
                                            { name: 'state', placeholder: 'State' },
                                            { name: 'number', placeholder: 'Tel', type: 'number' },
                                            { name: 'mobile', placeholder: 'Mobile', type: 'number' },
                                            { name: 'email', placeholder: 'Email', type: 'email' },
                                            { name: 'courses', placeholder: 'Courses' },
                                            { name: 'session', placeholder: 'Session' },
                                            { name: 'nationality', placeholder: 'Nationality' },
                                            { name: 'organization', placeholder: 'Name of Organization working for' },
                                            { name: 'designation', placeholder: 'Designation' },
                                            { name: 'development', placeholder: 'Your valuable suggestions for institutional development' },
                                            { name: 'achievements', placeholder: 'Special achievements' },
                                            { name: 'message', placeholder: 'Any other details' }
                                        ].map((input, index) => (
                                            <div key={index} className="col-lg-6">
                                                <div className="form-group">
                                                    <input
                                                        type={input.type || 'text'}
                                                        className="form-control"
                                                        placeholder={input.placeholder}
                                                        name={input.name}
                                                        value={formData[input.name]}
                                                        onChange={handleChange}
                                                    />
                                                    <p id={`${input.name}message`} className="message">{messages[input.name]}</p>
                                                </div>
                                            </div>
                                        ))}
                                        <div className="col-lg-6">
                                            <div className="form-group">
                                                <select
                                                    className="form-control"
                                                    id="exampleFormControlSelect1"
                                                    name="gender"
                                                    value={formData.gender}
                                                    onChange={handleChange}
                                                >
                                                    <option value="">Gender</option>
                                                    <option value="Male">Male</option>
                                                    <option value="Female">Female</option>
                                                </select>
                                                <p id="gendermessage" className="message">{messages.gender}</p>
                                            </div>
                                        </div>
                                        <div className="col-lg-12">
                                            <div className="text-center">
                                                <button type="submit" className="btn signup-btn" id="submit">Submit</button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SignupForm;
