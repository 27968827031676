import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
// import { Link } from 'react-router-dom';
import '../../Assets/Style.css';
import '../../Assets/css/animate.css'
import '../../Assets/css/bootstrap.min.css'
import '../../Assets/css/home-notification.css'
// import '../Assets/css/icofont.css'
import '../../Assets/css/magnific-popup.css'
import '../../Assets/css/meanmenu.css'
import '../../Assets/css/owl.carousel.min.css'
// import '../Assets/css/owl.theme.default.min.css'
 import '../../Assets/css/responsive.css'
import '../../Assets/css/slick.min.css'

import principalimg from '../../Assets/img/prinicpal.jpg'

function Dpharmacy() {
  return (
    <div>
      {/* Page Title */}
      <div className="page-title-area page-title-four">
        <div className="d-table">
          <div className="d-table-cell">
            <div className="page-title-item-two">
              <h2>D Pharmacy</h2>
              <h3>Duration 02 years</h3>
            </div>
          </div>
        </div>
      </div>
      {/* End Page Title */}

      {/* Doctor Details */}
      <div className="doctor-details-area pb-70">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="doctor-details-item">
                <div className="doctor-details-right">
                  <div className="doctor-details-biography">
                    <h3>D PHARMACY</h3>
                    <p>D.Pharmacy is a Diploma Course offered by J&K Paramedical Council. The duration of the course is 2 years.</p>
                  </div>
                  <div className="doctor-details-biography">
                    <h3>Eligibility</h3>
                    <p>No. Candidate shall be admitted to Diploma in Pharmacy unless he/she have passed Hr. Sec. Part-II (12th/10+2) or an equivalent examination from a recognised
statutory Board/University with Physics, Chemistry & Mathematics or Physics,
Chemistry & Biology including English as compulsory subjects having 40% marks; 
                    </p>
                    
                    <p>Admission of candidates to the Diploma in Pharmacy Part - I shall be made in order of merit on the basis of 'Pre-Pharmacy Test' conducted in accordance with the scheme of Examinations and syllabus laid-down by the BOPEE.</p>
                  </div>
                  <div className="doctor-details-biography">
                    <h3>Documents to be submitted</h3>
                    <ul className="raji">
                      <li>1. 10th and 12th original certificates with 2 set of Photostat copies.</li>
                      <li>2. Certificates of Higher Education if any.</li>
                      <li>3. State Domicile / Nationality Certificate.</li>
                      <li>4. Caste Certificate should be submitted in case of SC/ST candidates.</li>
                      <li>5. Twelve passport size photographs.</li>
                      <li>6. Transfer Certificate & migration certificate (for candidates who have completed
                        their qualifying examination under university outside Jammu University).</li>
                      <li>7. Conduct Certificate</li>
                      <li>8. Health fitness Certificate</li>
                    </ul>
                  </div>
                  <div className="doctor-details-biography">
                    <h3>Selection for Admission</h3>
                    <p>Total numbers of D.pharmacy seats are 60(Sixty). All the admissions are made through counseling conducted by Board of Professional Entrance Examination (BOPEE)</p>
                  </div>
                  <div className="doctor-details-biography">
                    <h3>FEE STRUCTURE OF THE COURSE</h3>
                    <table className="table">
                      <thead className="thead-dark">
                        <tr>
                          <th scope="col">S.No.</th>
                          <th scope="col">Particulars</th>
                          <th scope="col">Amount</th>
                          <th scope="col">1st Installment</th>
                          <th scope="col">2nd Installment</th>
                          <th scope="col">3rd Installment</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <th scope="row">1</th>
                          <td>1st Year</td>
                          <td>Rs.64,130</td>
                          <td>Rs.30,000</td>
                          <td>Rs.20,000</td>
                          <td>Rs.14,130</td>
                        </tr>
                        <tr>
                          <th scope="row">2</th>
                          <td>2nd Year</td>
                          <td>Rs.68,030</td>
                          <td>Rs.30,000</td>
                          <td>Rs.20,000</td>
                          <td>Rs.18,030</td>
                        </tr>
                        <tr>
                          <th scope="row">3</th>
                          <td>Bonafide Fee</td>
                          <td>Rs.100</td>
                          <td>-</td>
                          <td>-</td>
                          <td>-</td>
                        </tr>
                        <tr>
                          <th scope="row">4</th>
                          <td>Misc. Fee</td>
                          <td>Rs.200</td>
                          <td>-</td>
                          <td>-</td>
                          <td>-</td>
                        </tr>
                        <tr>
                          <th scope="row">5</th>
                          <td>Transcript Fee</td>
                          <td>Rs.500</td>
                          <td>-</td>
                          <td>-</td>
                          <td>-</td>
                        </tr>
                        <tr>
                          <th scope="row">6</th>
                          <td>Degree/Diploma Fee</td>
                          <td>Rs.350</td>
                          <td>-</td>
                          <td>-</td>
                          <td>-</td>
                        </tr>
                        <tr>
                          <th scope="row">7</th>
                          <td>Clinical Diary</td>
                          <td>Rs.300</td>
                          <td>-</td>
                          <td>-</td>
                          <td>-</td>
                        </tr>
                        <tr>
                          <th scope="row">8</th>
                          <td>Log Book</td>
                          <td>Rs.300</td>
                          <td>-</td>
                          <td>-</td>
                          <td>-</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="doctor-details-biography">
                    <h3>Note</h3>
                    <p>• Security will be refunded under the following conditions:</p>
                    <ul>
                      <li>a) Payable without interest after completion of course.</li>
                      <li>b) Subject to clearance of dues from (Labs, Library, Hostel & Transport).</li>
                      <li>c) In case of cancellation or withdrawal due to any reason security amount will be forfeited.</li>
                    </ul>
                    <p>• Exam, Eligibility, Registration, Sports, Digital and Promotion Fee will be charged as per university norms.</p>
                    <p>• Any other fee which is applicable/levied but not covered above shall also be charged.</p>
                    <p>• Fee once paid will not refunded under any circumstances.</p>
                    <p>• Please do not forget to obtain cash receipt after payment of fee.</p>
                    <p>• Fee paid without receipt will not be entertained later on.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dpharmacy;
