import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
// import { Link } from 'react-router-dom';
import '../../Assets/Style.css';
import '../../Assets/css/animate.css'
import '../../Assets/css/bootstrap.min.css'
import '../../Assets/css/home-notification.css'
// import '../Assets/css/icofont.css'
import '../../Assets/css/magnific-popup.css'
import '../../Assets/css/meanmenu.css'
import '../../Assets/css/owl.carousel.min.css'
// import '../Assets/css/owl.theme.default.min.css'
 import '../../Assets/css/responsive.css'
import '../../Assets/css/slick.min.css'

import Chairmanimg from '../../Assets/img/chairman.jpg'

function Chairman() {
  return (
    <>
      {/* Page Title */}
      <div className="page-title-area page-title-three">
        <div className="d-table">
          <div className="d-table-cell">
            <div className="page-title-item-two">
              <h2>M.S.Katoch</h2>
              <h3>Chairman</h3>
            </div>
          </div>
        </div>
      </div>
      {/* End Page Title */}

      {/* Doctor Details */}
      <div className="doctor-details-area pb-70">
        <div className="container">
          <div className="row">
            <div className="col-lg-5">
              <div className="doctor-details-item doctor-details-left">
                <img src={Chairmanimg} alt="Doctor" />
                <div className="doctor-details-contact">
                  <h3>Contact info</h3>
                  <ul>
                    <li>
                      <i className="icofont-ui-message"></i>
                      rgipjammu@gmail.com
                    </li>
                    <li>
                      <i className="icofont-location-pin"></i>
                      NH Bye Pass Road Narwal Jammu, J&K
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-7">
              <div className="doctor-details-item">
                <div className="doctor-details-right">
                  <div className="doctor-details-biography">
                    <h3>FROM THE DESK OF CHAIRMAN</h3>
                    <p>
                      The objective of establishing Pharmacy Institution in Jammu and kashmir is to train and equip the youth under this domain, the Pharmacy Education has 
                      expanded significantly in terms of numbers of institutions offering pharmacy programs at various levels, however Pharmacy education 
                      in Jammu And Kashmir is a distant dream for majority of the students. The Rajiv Gandhi Institute of Pharmacy is dedicated to teaching
                      & Education of the students who freely associate with their teacher in a common quest of Knowledge.
                    </p>
                    <br />
                    <p>
                    The knowledge driven society that we live in brings with its exciting and stimulating transitions where every individuals needs to come forward and 
                     commit to one’s work and keep oneself focused. The college is committed to nurture, intellectual growth with a holistic development of 
                     students even as it lays a strong emphasis on inculcating values & social concerns among them.
                    </p>
                    <br />
                    <p>
                    I am confident that you will turn out to be a useful citizen with the knowledge & wisdom you have acquired during your period of stay & 
                    study with us.
                    </p>  
                    <br />
                    <p>I wish you all the best.</p>  
                    <br />
                    <p>“The journey is quest of Knowledge begin with single step”.</p>    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Doctor Details */}
    </>
  );
}

export default Chairman;
