import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
// import { Link } from 'react-router-dom';
import '../../Assets/Style.css';
import '../../Assets/css/animate.css'
import '../../Assets/css/bootstrap.min.css'
import '../../Assets/css/home-notification.css'
// import '../Assets/css/icofont.css'
import '../../Assets/css/magnific-popup.css'
import '../../Assets/css/meanmenu.css'
import '../../Assets/css/owl.carousel.min.css'
// import '../Assets/css/owl.theme.default.min.css'
 import '../../Assets/css/responsive.css'
import '../../Assets/css/slick.min.css'

import principalimg from '../../Assets/img/prinicpal.jpg'

function Chairman() {
  return (
    <>
      {/* Page Title */}
      <div className="page-title-area page-title-three">
        <div className="d-table">
          <div className="d-table-cell">
            <div className="page-title-item-two">
              <h2>Mr GOURAV NANDA</h2>
              <h3>Principal</h3>
            </div>
          </div>
        </div>
      </div>
      {/* End Page Title */}

      {/* Doctor Details */}
      <div className="doctor-details-area pb-70">
        <div className="container">
          <div className="row">
            <div className="col-lg-5">
              <div className="doctor-details-item doctor-details-left">
                <img src={principalimg} alt="Doctor" />
                <div className="doctor-details-contact">
                  <h3>Contact info</h3>
                  <ul>
                    <li>
                      <i className="icofont-ui-message"></i>
                      rgipjammu@gmail.com
                    </li>
                    <li>
                      <i className="icofont-location-pin"></i>
                      NH Bye Pass Road Narwal Jammu, J&K
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-7">
              <div className="doctor-details-item">
                <div className="doctor-details-right">
                  <div className="doctor-details-biography">
                    <h3>FROM THE DESK OF PRINCIPAL</h3>
                    <p>
                    Welcome to the Rajiv Gandhi Institute of Pharmacy, a well established professional college under the Aegis of R.G Educational Society founded 
                    by an esteemed members of the managing societies to impact quality education in the field of Pharmaceutical sciences, The RGIP is recognized 
                    by Pharmacy council of India (PCI)-New Delhi, Approved & Affiliated with Govt. of J & K Paramedical council Jammu (J&K). The RGIP offers 
                    2 year’s Diploma course in Pharmacy & has imposed to start Bachelor of Pharmacy & Master of Pharmacy in coming years. The RGIP has excellent 
                    laboratories, unique exhaustive collection of books and journals it has an excellent team of well qualified trained & experienced teaching 
                    staff to impact though knowledge in theoretical and practical aspects of various spheres of Pharmacy. 
                    </p><p>We wish you the best of luck and hope you have a comfortable stay at our college.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Doctor Details */}
    </>
  );
}

export default Chairman;
