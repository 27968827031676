import React from 'react';
import { Link } from 'react-router-dom';
import '../../Assets/Style.css';
import '../../Assets/css/animate.css'
import '../../Assets/css/bootstrap.min.css'
import '../../Assets/css/home-notification.css'
// import '../Assets/css/icofont.css'
import '../../Assets/css/magnific-popup.css'
import '../../Assets/css/meanmenu.css'
import '../../Assets/css/owl.carousel.min.css'
// import '../Assets/css/owl.theme.default.min.css'
 import '../../Assets/css/responsive.css'
import '../../Assets/css/slick.min.css'


const RulesAndRegulations = () => {
  return (
    <div>
      {/* Page Title */}
      <div className="page-title-area page-title-four">
        <div className="d-table">
          <div className="d-table-cell">
            <div className="page-title-item">
              <h2>Rules and regulations</h2>
              <ul>
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <i className="icofont-simple-right"></i>
                </li>
                <li>Rules and regulations</li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      {/* Privacy Section */}
      <section className="privacy-area pt-100">
        <div className="container">
          <div className="privacy-item">
            <h2>RULES AND REGULATIONS OF THE COLLEGE</h2>
            <ol type="1">
              <li>The students will maintain complete discipline in the college and other academic areas.</li>
              <li>The students will be in complete uniform in the college, clinical area, community area and other academic areas. Civil dress is permitted on every Saturday. Extreme styles should be avoided. Revealing clothing which represents body to a degree that represents poor taste or unprofessional appearance.</li>
              <li>The students will adhere to time schedule of the college, failing which he/she will not be allowed to join the classes.</li>
              <li>All the students should wear the Identity Cards during the college timings.</li>
              <li>Total silence should be observed during classes / practicals.</li>
              <li>Mobile phones are strictly restricted in the college and other academic areas. If anybody is found using the cell phone the same will be ceased and will not be returned to the candidate.</li>
              <li>All the students are instructed to go through the academic calendar and follow it in totality.</li>
              <li>All the students are advised to follow the Hostel and Library rules which are in existence.</li>
              <li>Being a Health Sciences student 85% of attendance in theory and 100% in practical is a must, and students who fail to have the required attendance will not be sent for examinations. The attendance by the students will be faithfully recorded by the teaching staff and no one will yield to any external or parental pressure in this connection.</li>
              <li>Prior sanction from the concerned authority is a must in case of leave. Leave without sanction will be considered as absent and students will have to abide by the disciplinary actions of the college.</li>
              <li>Leave for medical reasons will not be considered and counted for calculation of attendance. Intimation of a medical condition and requesting leave is only a courtesy extended to the college by the student.</li>
              <li>Students are forbidden from taking an active part in political agitations or imitations directed against the authority.</li>
              <li>Discontinuance of the course is not permitted and even if somebody is interested to leave will have to remit the fee for the rest of years or else the original documents shall not be returned to the candidate.</li>
    
              <li>The college will pay 100% for Guest Lectures/ Workshops and other literary activities.</li>
              <li>All students are issued with an ID card after the admission procedure is over, the ID card is the Property of the college which needs to be returned after completion of the course.</li>
              <li>At the time of admission each student will give a written undertaking as per Hon’ble Supreme Court’s direction stating that he/she was not involved in any ragging activity in the past and that he /she would not indulge in it in future. He/she will report as soon as possible to the anti ragging squad / Principal/ warden to apprise all such incidents of ragging / conspiracy for ragging. If this undertaking is violated, the student would be liable to be expelled from the college and prosecuted.</li>
              <li><strong>Vacation:</strong> Students have their vacation according to the regulations of the University of Jammu / J&K Paramedical council and Pharmacy council of India (PCI)-New Delhi</li>
              <li><strong>Special leave:</strong> such leave will be granted in the case of serious illness or death of close relatives. The genuineness of the ground for leave must be certified to the satisfaction of the administration. The period of the leave will be deducted from the annual leave normally due.</li>
            </ol>
          </div>
          <div className="privacy-item">
            <h2>HOSTEL</h2>
            <ul>
              <li>1. The student desires of seeking admission in the hostel has to fill up Hostel Admission Form in duplicate on or before the prescribed date.</li>
              <li>2. Hostel admission form along with prescribed fee to be deposited in the college office for further necessary action.</li>
              <li>3. The I/C Hostel or Warden will allot the room to the students.</li>
              <li>4. The Hostel rooms are available on sharing basis and maximum number of three students will be allowed to stay in a room.</li>
              <li>5. All room are equipped with Bed(Cot), Study Table, Chair, Ward Robe, Fan, Cooler, Curtains, CFL etc..</li>
              <li>6. The students are advised not to use Loud Speakers, Music Systems, Iron, Heaters etc. In case of violations a fine of Rs.1000/- shall be imposed.</li>
              <li>7. The material allotted to the students is the property of the college and the students are supposed to return the same in good condition damage if any will be recovered accordingly.</li>
              <li>8. The students are advised maintain peace in the hostel and in case of any problem contact Warden / In-charge / Principal to solve their problem and even if the problem remain unsolved they can contact the Chairman.</li>
              <li>9. Guests are not allowed in the Hostel.</li>
              <li>10. It is the responsibility of the students to keep their rooms neat and clean.</li>
              <li>11. Students are not allowed to stay out or go out without prior permission of the Warden / Principal.</li>
              <li>12. Night Outs are allowed only & only if Parents request is accompanied with Application.</li>
              <li>13. The students will be allowed to go to market once in a month that too along with Warden / Faculty / Asst. Warden.</li>
              <li>14. Surprise inspection will be carried out at regular intervals and the best room award will be given.</li>
              <li>15. In order to imbibe responsibility among students the college will elect a hostel committee comprising of Perfect, Vice Perfect and Mess Secretary. They will take care of various activities of hostel.</li>
              <li>16. Students are advised not to use the mobiles in excess in hostel.</li>
              <li>17. No student can leave the Hostel in between the Session and if desire so, will have to remit the entire fee.</li>
              <li>18. Detailed Menu in consultation with students is prepared and implemented in true letter and spirit.</li>
              <li>19. Special food on individual demand will not be prepared.</li>
              <li>20. Meals should not be taken to rooms and self cooking is not allowed.</li>
              <li>21. All students are requested not to waste the food, if anybody found will be fined Rs.200/- on spot.</li>
              <li>22. Students should stick to the meal timings and wear formals during meal time.</li>
            </ul>
          </div>
        </div>
      </section>
    </div>
  );
};

export default RulesAndRegulations;
