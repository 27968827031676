import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

// Import your images here
import image1 from '../Assets/img/gallery/pic 1.jpg'
import image2 from '../Assets/img/gallery/pic 2.jpg'
import image3 from '../Assets/img/gallery/pic 3.jpg'
// import image4 from '../Assets/img/gallery/pic 4.jpg'
import image4 from '../Assets/img/gallery/pic 5.jpg'

const images = [
  {
    src: image1,
    alt: 'Image 1',
    // title: 'Sunset',
    // description: 'A beautiful sunset ovezxfgxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxr the mountains.'
  },
  {
    src: image2,
    alt: 'Image 2',
    // title: 'Beach View',
    // description: 'Enjoying a relaxing day at the beach.'
  },
  {
    src: image3,
    alt: 'Image 3',
    // title: 'Cityscape',
    // description: 'A stunning view of the city skyline at night.'
  },
  {
    // src: image4,
    // alt: 'Image 4',
    // title: 'Nature Trail',
    // description: 'Exploring the lush greenery on a nature trail.'
  },
  {
    src: image4,
    alt: 'Image 4',
    // title: 'Waterfall',
    // description: 'Standing in awe of the majestic waterfall.'
  },
  // {
  //   src: image6,
  //   alt: 'Image 6',
  //   title: 'Mountain Peak',
  //   description: 'Reaching the summit of the highest peak.'
  // }
];

function Gallery() {
  return (
    <div className="container mt-3">
      <div className="row">
        {images.map((image, index) => (
          <div className="col-lg-4 col-md-6 mb-4" key={index}>
            <div className="card h-100">
              <img className="card-img-top" src={image.src} alt={image.alt} />
              <div className="card-body">
                <h5 className="card-title">{image.title}</h5>
                <p className="card-text">{image.description}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Gallery;
