import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faPhone, faLocationDot } from '@fortawesome/free-solid-svg-icons';
import '../../Assets//Style.css'; // Ensure this path is correct


const Footer = () => {
  return (
      <footer className="pb-70">
          <div className="container">
              <div className="row text-center">
                  <div className="col-sm-6 col-lg-4">
                      <div className="footer-item">
                          <div className="footer-contact">
                              <h3>Contact Us</h3>
                              <ul>
                                <li>
                                    <div>
                                        <i className="icofont-ui-message"><FontAwesomeIcon icon={faEnvelope} size="2xs" /></i>
                                        <a href="mailto:rgipjammu@gmail.com">rgipjammu@gmail.com</a>
                                    </div>
                                  {/* <a href="mailto:info@rgcnjammuorg.com">info@rgcnjammuorg.com</a> */}
                                </li>
                                <li>
                                  <i className="icofont-stock-mobile"><FontAwesomeIcon icon={faPhone}  size="2xs" /></i>
                                  <a href="tel:+07554332322">Call: +91 7006153585</a>
                          
                                  {/* <a href="tel:+236256256365">Call: +91 9419187867</a> */}
                                </li>
                                <li>
                                  <i className="icofont-location-pin"><FontAwesomeIcon icon={faLocationDot}  size="2xs" /></i>
                                  NH Bye Pass Road Narwal Jammu, J&K
                                  </li>
                              </ul>
                          </div>
                      </div>
                  </div>
                  <div className="col-sm-6 col-lg-2">
                      <div className="footer-item">
                          <div className="footer-quick">
                              <h3>Quick Links</h3>
                              <ul>
                                  <li>
                                      <a href="">Notifcations</a>
                                  </li>
                                  <li>
                                      <a href="Gallery">Gallery</a>
                                  </li>
                                  <li>
                                      <a href="Facilities">Our facilities</a>
                                  </li>
                                  <li>
                                      <a href="application">Application</a>
                                  </li>
                                  <li>
                                      <a href="contact">Contact us</a>
                                  </li>
                              </ul>
                          </div>
                      </div>
                  </div>
                  <div className="col-sm-6 col-lg-3 text-center">
                      <div className="footer-item">
                          <div className="footer-quick">
                              <h3>Our Courses</h3>
                              <ul>
                                  <li>
                                      <a href="D-pharmacy">D. Pharmacy</a>
                                  </li>
                                  <li>
                                      <a href="B-pharmacy">B. Pharmacy</a>
                                  </li>
                                  <li>
                                      <a href="M-pharmacy">M. Pharmacy</a>
                                  </li>
                              </ul>
                          </div>
                      </div>
                  </div>
                  <div className="col-sm-6 col-lg-3">
                      <div className="footer-item">
                          <div className="footer-quick">
                              <h3>College Info</h3>
                              <ul>
                                  <li>
                                      <a href="Introducation">Introduction</a>
                                  </li>
                                  <li>
                                      <a href="Chairman">Chairman Message</a>
                                  </li>
                                  <li>
                                      <a href="Director">Director Message</a>
                                  </li>
                                  <li>
                                      <a href="Principal">Principal Message</a>
                                  </li>
                                  <li>
                                      <a href="Rules-regulations">Rules and regulations</a>
                                  </li>
                                  {/* <li>
                                      <a href="our-other-institutions.aspx">Our Other Institution</a>
                                  </li> */}
                                  
                                  
                              </ul>
                          </div>
                      </div>
                  </div>
              </div>
          </div>

          {/* Copyright Section */}
          <div className="copyright-area">
              <div className="container">
                  <div className="copyright-item">
                      <p>Rajiv Gandhi Institute of Pharmacy  Copyright ©2020 Design & Developed by <a
                          href="https://ejunctionjk.com/" target="_blank" rel="noopener noreferrer">Ejunction</a></p>
                  </div>
              </div>
          </div>
          {/* End Copyright Section */}
      </footer>
  );
}

export default Footer;
