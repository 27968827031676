import React from 'react';
import '../Assets/Style.css';
import Slider from './Views/Slider';
import '../Assets/css/animate.css';
import '../Assets/css/bootstrap.min.css';
import '../Assets/css/home-notification.css';
// import '../Assets/css/icofont.css';
import '../Assets/css/magnific-popup.css';
import '../Assets/css/meanmenu.css';
import '../Assets/css/owl.carousel.min.css';
import '../Assets/css/owl.theme.default.min.css';
import '../Assets/css/responsive.css';
import '../Assets/css/slick.min.css';

import expertiseimg from '../Assets/img/cover/home-buses.gif';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPrescriptionBottleMedical,faBookMedical,faBookOpenReader,faUserGraduate,faCalendarDays, faPersonChalkboard,faBuilding, faComputer, faPeopleRoof} from '@fortawesome/free-solid-svg-icons';
// import '../Assets/css/slick-theme.css';
// import '../Assets/RetroStyle.css';

function Home() {
  return (
    <div>
      <Slider />
      <div className='home-body'>
        <div className="about-area pt-100 pb-70">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-7">
                <div className="about-item about-right">
                  <img src="assets/img/home-one/5.png" alt="About" />
                  <h2>About Our Institution</h2>
                  <p>
                  Be ready to enter pharmacy practice as confident, prepared pharmacist motivated by excellence
                  and desire to serve. Study under faculty members who are expert in their respective fields and
                  grow your knowledge and skills through innovative curriculum and state of art simulation labs.
                  The Rajiv Gandhi Institute of Pharmacy will educate and equip you to practice in the complex
                  ever changing arena of pharmacy.                  </p>
                  <a href="/Introducation">Know More</a>
                </div>
              </div>
              <div className="col-lg-5">
                <div className="panel panel-default">
                  <div className="panel-heading">
                    <i className="icofont-notification"></i>
                    <b>Latest Notifications / Announcement & Results</b>
                  </div>
                  <div className="panel-body">
                    <div className="row">
                      <div className="col-xs-12">
                        <ul className="demo1">
                          {/* Add your conditional rendering logic for notifications here */}
                          <h1>Sorry No Data Found</h1>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="panel-footer">
                    <ul className="pagination pull-right" style={{ margin: '0px' }}>
                      <li><a href="#" className="prev"><i className="fa fa-chevron-down" aria-hidden="true"></i></a></li>
                      <li><a href="#" className="next"><i className="fa fa-chevron-up" aria-hidden="true"></i></a></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <section className="expertise-area pb-70">
          <div className="container">
            <div className="section-title">
              <h2>Courses offered</h2>
            </div>
            <div className="row">
              <div className="col-sm-6 col-lg-4">
                <div className="department-item">
                <FontAwesomeIcon icon={faPrescriptionBottleMedical} size="2xl" style={{color: "#0046c0",}} />
                  <h3>D. Pharmacy</h3>
                  <p>D.Pharmacy is a Diploma Course In Pharmacy offered by J&K Paramedical Council. The duration of the course is 2 years.</p>
                  <a href="D-pharmacy" className="btn signup-btn">Read More</a>
                </div>
              </div>
              <div className="col-sm-6 col-lg-4">
                <div className="department-item">
                <FontAwesomeIcon icon={faPrescriptionBottleMedical} size="2xl" style={{color: "#0046c0",}} />
                  <h3>B. Pharmacy</h3>
                  <p>B.Pharmacy is a Bachelor Degree In Pharmacy offered by J&K Paramedical Council. The duration of the course is 4 years.</p>
                  <a href="B-pharmacy" className="btn signup-btn">Read More</a>
                </div>
              </div>
              <div className="col-sm-6 col-lg-4"> 
                <div className="department-item">
                <FontAwesomeIcon icon={faPrescriptionBottleMedical} size="2xl" style={{color: "#0046c0",}} />
                  <h3>M. Pharmacy</h3>
                  <p>M.Pharmacy is a Master Degree In Pharmacy offered by J&K Paramedical Council. The duration of the course is 2 years.</p>
                  <a href="M-pharmacy" className="btn signup-btn">Read More</a>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="services-area pb-70">
          <div className="container">
            <div className="section-title">
              <h2>Our Facilities</h2>
            </div>
            <div className="row">
              <div className="col-sm-6 col-lg-3 wow fadeInUp" data-wow-delay=".3s">
                <div className="service-item border border-primary">
                  <div className="service-front ">
                  <FontAwesomeIcon icon={faBookMedical} size="2xl" style={{color: "#0046c0",}} />
                    <h3>Book Bank</h3>
                    <p>Our aim is to encourage the meritorious students by providing information and educational assistance from the institute itself at a subsidized rate.</p>
                  </div>
                  <div className="service-end">
                  <FontAwesomeIcon icon={faBookMedical} size="2xl" style={{color: "#f8f7f7",}} />
                    <h3>Book Bank</h3>
                    <p>Our aim is to encourage the meritorious students by providing information and educational assistance from the institute itself at a subsidized rate.</p>
                    <a href="/Facilities">Contact Us</a>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-lg-3 wow fadeInUp" data-wow-delay=".5s">
                <div className="service-item border border-primary">
                  <div className="service-front">
                    <FontAwesomeIcon icon={faUserGraduate} size="2xl" style={{color: "#0046c0",}}  />
                    <h3>Teaching Aids</h3>
                    <p>Mode of learning for students rely on all types of audio and Visual aids, graphic aids, books, model, charts, poster are rendered in teaching sessions to make it easier and interesting for studentsto retain and recall information during assessments and beyond.</p>
                  </div>
                  <div className="service-end">
                    <FontAwesomeIcon icon={faUserGraduate} size="2xl" style={{color: "#f8f7f7",}} />
                    <h3>Teaching Aids</h3>
                    <p>Mode of learning for students rely on all types of audio and Visual aids,graphic aids, books, model, charts, poster are rendered in teaching sessions to make it easier and interesting for studentsto retain and recall information during assessments and beyond.</p>
                    <a href="/Facilities">Contact Us</a>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-lg-3 wow fadeInUp" data-wow-delay=".7s">
                <div className="service-item border border-primary">
                  <div className="service-front">
                  <FontAwesomeIcon icon={faBookOpenReader} size="2xl" style={{color: "#0046c0",}} />
                    <h3>Internal Examinations</h3>
                    <p>Three internal assessment examinations are conducted in every academic year in order to check the performance of the students. The quarterly progress report is sent to the parents/guardians.</p>
                  </div>
                  <div className="service-end" size="2xl" style={{color: "#f8f7f7",}}>
                  <FontAwesomeIcon icon={faBookOpenReader} />
                    <h3>Internal Examinations</h3>
                    <p>Three internal assessment examinations are conducted in every academic year in order to check the performance of the students. The quarterly progress report is sent to the parents/guardians.</p>
                    <a href="/Facilities">Contact Us</a>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-lg-3 wow fadeInUp" data-wow-delay=".9s">
                <div className="service-item border border-primary">
                  <div className="service-front">
                  <FontAwesomeIcon icon={faCalendarDays}  size="2xl" style={{color: "#0046c0",}}/>
                    <h3>Academic Calendar</h3>
                    <p>The academic calendar is prepared in advance every year. All the courses of the college run strictly according to the academic Calendar. A copy of the Calendar is available from the office upon demand.</p>
                  </div>
                  <div className="service-end">
                  <FontAwesomeIcon icon={faCalendarDays}  size="2xl" style={{color: "#f8f7f7",}}/>
                    <h3>Academic Calendar</h3>
                    <p>The academic calendar is prepared in advance every year. All the courses of the college run strictly according to the academic Calendar. A copy of the Calendar is available from the office upon demand.</p>
                    <a href="/Facilities">Contact Us</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="expertise-area pb-70">
          <div className="container">
            <div className="section-title">
              <h2>Our Expertise</h2>
            </div>
            <div className="row align-items-center">
              <div className="col-lg-6">
                <div className="expertise-item">
                  <div className="row">
                    <div className="col-sm-6 col-lg-6 wow fadeInUp" data-wow-delay=".3s">
                      <a href="javascript:void(0)">
                        <div className="expertise-inner">
                          <i className="icofont-doctor-alt"> <FontAwesomeIcon icon={faPersonChalkboard} /></i>
                          <a href="teacherlist"><h3>Certified Teachers</h3></a>
                        </div>
                      </a>
                    </div>
                    <div className="col-sm-6 col-lg-6 wow fadeInUp" data-wow-delay=".5s">
                      <a href="javascript:void(0)">
                        <div className="expertise-inner">
                          <i className="icofont-building"><FontAwesomeIcon icon={faBuilding} /></i>
                          <h3>Well Infrastructure</h3>
                        </div>
                      </a>
                    </div>
                    <div className="col-sm-6 col-lg-6 wow fadeInUp" data-wow-delay=".3s">
                      <a href="javascript:void(0)">
                        <div className="expertise-inner">
                          <i className="icofont-network"><FontAwesomeIcon icon={faComputer} /></i>
                          <h3>Smart Classes</h3>
                        </div>
                      </a>
                    </div>
                    <div className="col-sm-6 col-lg-6 wow fadeInUp" data-wow-delay=".5s">
                      <a href="javascript:void(0)">
                        <div className="expertise-inner">
                          <i className="icofont-mic"><FontAwesomeIcon icon={faPeopleRoof} /></i>
                          <h3>Seminar Room</h3>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="expertise-item">
                  <div className="expertise-right">
                    <img src={expertiseimg} alt="Expertise" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default Home;
