import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
// import { Link } from 'react-router-dom';
import '../../Assets/Style.css';
import '../../Assets/css/animate.css'
import '../../Assets/css/bootstrap.min.css'
import '../../Assets/css/home-notification.css'
// import '../Assets/css/icofont.css'
import '../../Assets/css/magnific-popup.css'
import '../../Assets/css/meanmenu.css'
import '../../Assets/css/owl.carousel.min.css'
// import '../Assets/css/owl.theme.default.min.css'
 import '../../Assets/css/responsive.css'
import '../../Assets/css/slick.min.css'

import directorimg from '../../Assets/img/director.jpg'

function Chairman() {
  return (
    <>
    {/* Page Title */}
    <div className="page-title-area page-title-three">
      <div className="d-table">
        <div className="d-table-cell">
          <div className="page-title-item-two">
            <h2>DR. HEENA</h2>
            <h3>Director's Message</h3>
          </div>
        </div>
      </div>
    </div>
    {/* End Page Title */}

    {/* Doctor Details */}
    <div className="doctor-details-area pb-70">
      <div className="container">
        <div className="row">
          <div className="col-lg-5">
            <div className="doctor-details-item doctor-details-left">
              <img src={directorimg} alt="Doctor" />
              <div className="doctor-details-contact">
                <h3>Contact info</h3>
                <ul>
                  <li>
                    <i className="icofont-ui-message"></i>
                    rgipjammu@gmail.com
                  </li>
                  <li>
                    <i className="icofont-location-pin"></i>
                    NH Bye Pass Road Narwal Jammu, J&K
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-lg-7">
            <div className="doctor-details-item">
              <div className="doctor-details-right">
                <div className="doctor-details-biography">
                  <h3>FROM THE DESK OF DIRECTOR</h3>
                  <p>
                  We serve as a resource as you embark upon your journey into the world of professional pharmacy education. We will provide you with the information about our 
                  program as well as the unique learning atmosphere at RAJIV GANDHI INSTITUTE OF PHARMACY. The college has been established in this same great 
                  tradition, educating and equipping young pharmacists to enter practice as confident,prepared individuals motivated by excellence and desire to 
                  serve. RAJIV GANDHI COLLEGE OF PHARMACY will equip you to competently practice in the complex and ever-changing arena of pharmacy. The college 
                  expounds upon the commitment and intellectual   tradition brings to bear on the subject matter we study as pharmacists. The philosophy of our 
                  college is to cultivate a learning community committed to education,community outreach and development.
                  </p>
                  <br />
                  <p>
                  With our ambitions higher Our commitment deeper and our efforts greater. I invite you to explore our college as we rededicate ourselves everyday 
                  to provide an excellent education for our students. One that they will cherish for lifetime, one that will make them kind and compassionate human 
                  beings.I welcome you all to explore our college as a possibility for your professional training .
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    {/* End Doctor Details */}
  </>
  );
}

export default Chairman;
