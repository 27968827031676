import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
// import { Link } from 'react-router-dom';
import '../../Assets/Style.css';
import '../../Assets/css/animate.css'
import '../../Assets/css/bootstrap.min.css'
import '../../Assets/css/home-notification.css'
// import '../Assets/css/icofont.css'
import '../../Assets/css/magnific-popup.css'
import '../../Assets/css/meanmenu.css'
import '../../Assets/css/owl.carousel.min.css'
// import '../Assets/css/owl.theme.default.min.css'
 import '../../Assets/css/responsive.css'
import '../../Assets/css/slick.min.css'

import comingsoon from '../../Assets/img/cover/comingsoon.png'

function Dpharmacy() {
  return (
    <div>
    {/* Page Title */}
    <div className="page-title-area page-title-three">
      <div className="d-table">
        <div className="d-table-cell">
          <div className="page-title-item-two">
            <h2>B Pharmacy</h2>
            {/* <h3>Duration 02 years</h3> */}
          </div>
        </div>
      </div>
    </div>
    {/* End Page Title */}

    {/* Doctor Details */}
    <div className="doctor-details-area pb-70">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="doctor-details-item">
            <img src={comingsoon} alt="Doctor" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  );
}

export default Dpharmacy;
