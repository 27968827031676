import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
// import { Link } from 'react-router-dom';
import '../../Assets/Style.css';
import '../../Assets/css/animate.css'
import '../../Assets/css/bootstrap.min.css'
import '../../Assets/css/home-notification.css'
// import '../Assets/css/icofont.css'
import '../../Assets/css/magnific-popup.css'
import '../../Assets/css/meanmenu.css'
import '../../Assets/css/owl.carousel.min.css'
// import '../Assets/css/owl.theme.default.min.css'
 import '../../Assets/css/responsive.css'
import '../../Assets/css/slick.min.css'


function Vision() {
  return (
    <div>
      <div className="page-title-area page-title-four">
        <div className="d-table">
          <div className="d-table-cell">
            <div className="page-title-item">
              <h2>Vision and Mission</h2>
              <ul>
                <li>
                  <a href="Default.aspx">Home</a>
                </li>
                <li>
                  <i className="icofont-simple-right"></i>
                </li>
                <li>Vision and Mission</li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="about-area pt-100 pb-70">
        <div className="container">
          <div className="row align-items-center border shadow-lg p-3 mb-5 bg-white rounded ">
            <div className="col-lg-2">
              <div className="about-item">
                <h2>VISION</h2>
              </div>
            </div>
            <div className="col-lg-9">
              <div className="about-item about-right">
                
                <p>
                To prepare students with an integrated technology oriented education for a better career. We
recognize the imperative of imparting professional experience in every respect which prepares
our children for global amend. We believe that a curriculum of excellence with a global
dimension is central to the education of the children, so it prepares them to face the challenges of
21st century with confidence and strength of a character.                </p>
              </div>
            </div>
            
          </div>
            <div className="row align-items-center border shadow-lg p-3 mb-5 bg-white rounded ">
                <div className="col-lg-2">
                <div className="about-item ">
                    <h2>MISSION</h2>
                </div>
                </div>
                <div className="col-lg-9">
                <div className="about-item about-right">
                    <p> To develop better than the best professionals for the economic development of the country.
RAJIV GANDHI INSTITUTE OF PHARMACY‘S mission is to provide a learning environment
that encourages children to bring out the best in themselves and enables their all round
development through the joy of learning, enduring values and the celebration of diversity.</p>
                </div>
                </div> 
            </div>
            <div className="row align-items-center border shadow-lg p-3 mb-5 bg-white rounded ">
                <div className="col-lg-2">
                <div className="about-item">
                    <h2>GOALS</h2>
                </div>
                </div>
                <div className="col-lg-9">
                <div className="about-item about-right">
                  <p>
                  To excel in various disciplines of education. To encourage research and development. In various
departments in thrust areas identified by various national agencies. Our values and attributes,
which align with our guiding statements, are integral to create a college culture and climate to
realise our educational goals.
                </p>
                </div>
                </div> 
                
            </div>
            <div className="row align-items-center border shadow-lg p-3 mb-5 bg-white rounded ">
                <div className="col-lg-4">
                <div className="about-item ">
                    <h2>OUR VALUES AND ATTRIBUTES</h2>
                </div>
                </div>
                <div className="col-lg-6">
                <div className="about-item about-right">
                  
                <ol type='.'>
                  <li>CLEANLINESS</li>
                  <li>COMPASSION</li>
                  <li>COMMITMENT</li>
                  <li>DETERMINATION</li>
                  <li>HONESTY </li>
                  <li>INTEGRITY</li>
                  <li>PERSEVERANCE</li>
                  <li>RESPECT</li>
                  <li>SELF-DISCIPLINE</li>
                  <li>TEAMWORK</li>
                  <li>TRUST</li>
                  <li>EMPATHY</li>
                </ol>
                </div>
                </div> 
                
            </div>
            <h4 className='rounded-pill shadow-lg p-5 mb-5 bg-white rounded '> OUR MAIN OBJECTIVE IS TO PROVIDE A SAFE, SECURE AND CONDUCIVE
ENVIRONMENT IN WHICH EVERY STUDENT CAN DARE TO DREAM, ENJOY LEARNING,
ACHIEVE EXCELLENCE AND BECOME A LIFE LONG LEARNER.</h4>
        </div>
      </div>
    </div>
  );
}

export default Vision;
