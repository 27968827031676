import React from 'react';
import '../Assets/Style.css';
import '../Assets/css/animate.css';
import '../Assets/css/bootstrap.min.css';
import '../Assets/css/home-notification.css';
import '../Assets/css/magnific-popup.css';
import '../Assets/css/meanmenu.css';
import '../Assets/css/owl.carousel.min.css';
import '../Assets/css/owl.theme.default.min.css';
import '../Assets/css/responsive.css';
import '../Assets/css/slick.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faBookMedical, faUserGraduate, faCodeCommit, faPenNib, faSchool, faRoad, faCookieBite, faBookOpen, faBus} from '@fortawesome/free-solid-svg-icons';
const Facilities = () => (
  <>
    {/* Page Title */}
    <div className="page-title-area page-title-four">
      <div className="d-table">
        <div className="d-table-cell">
          <div className="page-title-item">
            <h2>Facilities</h2>
            <ul>
              <li>
                <a href="/">Home</a>
              </li>
              <li>
                <i className="icofont-simple-right"></i>
              </li>
              <li>Facilities</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    {/* End Page Title */}

    {/* About */}
    <div className="about-area pt-100 pb-70">
      {/* Raji Gandhi Facilities */}
      <section className="facilities-area pb-70">
        <div className="container">
          <div className="section-title">
            <h2>Our Facilities</h2>
          </div>
          <div className="row">
            {facilities.map((facility, index) => (
              <div key={index} className="col-sm-9 col-lg-4 wow fadeInUp mb-4" data-wow-delay={`.${3 + index}s`}>
                <div className="facilities-item">
                  {/* <div className="facilities-front "> */}
                    <i className="gh">{facility.icon}</i>
                    <h3>{facility.title}</h3>
                    <p>{facility.description}</p>
                  {/* </div> */}
                  {/* <div className="facilities-end">
                    <i className={facility.icon}></i>
                    <h3>{facility.title}</h3>
                    <p>{facility.description}</p>
                    
                  </div> */}
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
      {/* End Raji Gandhi Facilities */}
    </div>
    {/* End About */}
  </>
);

export default Facilities;

const facilities = [
  {
    icon: <FontAwesomeIcon icon={faCodeCommit} size="2xl" style={{color: "#0046c0",}}   />,
    title: 'Committees',
    description: 'Apart from the academic affairs the students are trained to organize various events by involving them in various committees such as: Cultural Committee, Sports committee, Disciplinary Committee, Anti-Ragging Committee, Students Welfare committee & Hostel / Mess Committee.'
  },
  
  {
    icon: <FontAwesomeIcon icon={faUserGraduate} size="2xl" style={{color: "#0046c0",}}  />,
    title: 'Teaching Aids',
    description: 'Mode of learning for students rely on all types of audio and Visual aids, graphic aids, books, model, charts, poster are rendered in teaching sessions to make it easier and interesting for studentsto retain and recall information during assessments and beyond.'
  },
  {
    icon: <FontAwesomeIcon icon={faPenNib} size="2xl" style={{color: "#0046c0",}} />,
    title: 'Seminars/ Debates',
    description: 'College believes that overall development of the student is an effective component for the success of the professional performance. Periodical Seminars, Debates and Guest Lectures are conducted by predominant academicians and professionals.'
  },
 
  {
    icon: <FontAwesomeIcon icon={faSchool} size="2xl" style={{color: "#0046c0",}} />,
    title: 'Student Support & Feedback',
    description: 'Management of the College takes periodical interactions with the students in order for internal quality assurance. We apply all sincere and possible efforts to shape the career of the students so as to make them                   not only a complete professional but also a responsible citizen of the country. Students are provided with all sorts of modern amenities and extra ordinary care is taken towards over all development of their personality.'
  },
  {
    icon: <FontAwesomeIcon icon={faRoad} size="2xl" style={{color: "#0046c0",}}/>,
    title: 'Placement Cell',
    description: 'College firmly believes that the success of an institution depends on great extent, upon the acceptability of its professionals in the market which in turn depends upon the quality and standard of qualification and education imparted to the students. We assist our students to seek employment in good organization and also invite organizations to select students from our premises; all possible efforts are applied to make best use of the talent of our student.'
  },
  {
    icon: <FontAwesomeIcon icon={faCookieBite} size="2xl" style={{color: "#0046c0",}}/>,
    title: 'Co Curricular Activities',
    description: 'For the overall development of the students of college organizes various types of co curricular activities. The college celebrates FRESHERS PARTY, INDEPENDENCE DAY, SPORTS MEET, ANNUAL DAY, FAREWELL PROGRAMME and all other important activities. The college has the facility for Sports, Outdoor Activities and Indoor Activities.'
  },
  {
    icon: <FontAwesomeIcon icon={faBookOpen} size="2xl" style={{color: "#0046c0",}}/>,
    title: 'Education Loan',
    description: 'College provides assistance in providing educational loan for the students who require financial support for the education.'
  },
  {
    icon: <FontAwesomeIcon icon={faSchool} size="2xl" style={{color: "#0046c0",}} />,
    title: 'Hostel',
    description: ' The college has separate hostel for boys and girls. The hostel is spacious and equipped with all the furniture and accessories needed for the happy and good stay of the candidates and study.'
  },
  {
    icon: <FontAwesomeIcon icon={faBookMedical} size="2xl" style={{color: "#0046c0",}} />,
    title: 'Book Bank',
    description: 'Our aim is to encourage the meritorious students by providing information and educational assistance from the institute itself at a subsidized rate.'
  },

  {
    icon: <FontAwesomeIcon icon={faBus}  size="2xl" style={{color: "#0046c0",}}/>,
    title: 'Transport',
    description: ' The college provides transport facility to the students and faculty to and fro of the college, clinical, community and educational visits. All the students are requested to avail the transport facility for the smooth and safe travel.'
  },
 
  
];
