import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
// import { Link } from 'react-router-dom';
import '../../Assets/Style.css';
import '../../Assets/css/animate.css'
import '../../Assets/css/bootstrap.min.css'
import '../../Assets/css/home-notification.css'
// import '../Assets/css/icofont.css'
import '../../Assets/css/magnific-popup.css'
import '../../Assets/css/meanmenu.css'
import '../../Assets/css/owl.carousel.min.css'
// import '../Assets/css/owl.theme.default.min.css'
 import '../../Assets/css/responsive.css'
import '../../Assets/css/slick.min.css'

function Introducation() {
  return (
    <div>
      <div className="page-title-area page-title-four">
        <div className="d-table">
          <div className="d-table-cell">
            <div className="page-title-item">
              <h2>About</h2>
              <ul>
                <li>
                  <a href="Default.aspx">Home</a>
                </li>
                <li>
                  <i className="icofont-simple-right"></i>
                </li>
                <li>About</li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="about-area pt-100 pb-70">
        <div className="container">
          <div className="row align-items-center">
            {/* <div className="col-lg-6">
              <div className="about-item">
                <div className="about-left">
                  <img src="imgs/home-about.jpg" alt="About" />
                </div>
              </div>
            </div> */}
            {/* <div className="col-lg-6"> */}
              <div className="about-item about-right">
                {/* <img src="assets/img/home-one/5.png" alt="About" /> */}
                <h2>INTRODUCTION</h2>
                {/* <h5>(Recognized by Pharmacy Council of India (PCI)-New Delhi) (Approved/Affiliated with J & K Govt. Paramedical Council Jammu J & K)</h5> */}
                <p>
                 Be ready to enter pharmacy practice as confident, prepared pharmacist motivated by excellence
and desire to serve. Study under faculty members who are expert in their respective fields and
grow your knowledge and skills through innovative curriculum and state of art simulation labs.
The Rajiv Gandhi Institute of Pharmacy will educate and equip you to practice in the complex
ever changing arena of pharmacy.
                </p>
                <p>
               India’s gigantic pharmaceutical industry with increasing number of hospitals and nursing homes
clearly reflect the number of opportunities and the scope of taking up career in pharmacy
.Pharmacy deals with health care and its main purpose is to promote safe and effective use of
pharmaceutical products. The professional working in this field is generally called as
PHARMACIST.
                </p>
                <p>
                RAJIV GANDHI INSTITUTE OF PHARMACY has emerged as the preferred choice for the
youth all over JAMMU AND KASHMIR in the field of pharmaceutical education owing to its
innovative education, pioneering research and inter-disciplinary practice development.
                </p>
                <p>
                RAJIV GANDHI INSTITUTE OF PHARMACY is established to promote excellence in
pharmaceutical education and research. The college is recognized by PHARMACY COUNCIL
OF INDIA (PCI- NEW DELHI) (APPROVED/AFFILIATED WITH J&K GOVT. PARA
MEDICAL COUNCIL JAMMU (J & K)).
                </p>
              </div>
            {/* </div> */}
            <div className="doctor-details-biography">
                    <h3>RAJIV GANDHI INSTITUTE OF PHARMACY AT GLANCE</h3>
                    <table className="table">
                      <thead className="thead-dark">
                        <tr>
                        
                          <th scope="col">Particulars</th>
                          <th scope="col">Details</th>
                          
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                         
                          <th>INSTITUTE NAME</th>
                          <td>RAJIV GANDHI INSTITUTE OF PHARMACY</td>
                          
                        </tr>
                        <tr>
                         
                          <th>ESTD.YEAR:</th>
                          <td>1994</td>
                          
                        </tr>
                        <tr>
                          <th>AFFLIATED/RECOGNIZED BY (vide PCI-1707)</th>
                          <td>PHARMACY COUNCIL OF INDIA, NEW DELHI, GOVT.PARAMEDICAL COUNCIL OF JAMMU.(J&k)</td>
                        </tr>
                        <tr>
                          <th>TYPE</th>
                          <td>PRIVATE, (SELF FINANCING)</td>
                        </tr>
                        <tr>
                          <th>PROGRAMME OFFERED</th>
                          <td>DIPLOMA IN PHARMACY (D.PHARM)</td>
                        </tr>
                        <tr>
                          <th>ADDRESS</th>
                          <td>NH BYE PASS ROAD, NARWAL, JAMMU. J&K- 181152</td>
                        </tr>
                        <tr>
                          <th>CONTACT NUMBER </th>
                          <td>7006153585</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Introducation;
