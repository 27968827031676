import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

import video1 from '../../Assets/video/video1.mp4';
import video2 from '../../Assets/video/video2.mp4';
import video3 from '../../Assets/video/video3.mp4';
import video4 from '../../Assets/video/video4.mp4';

const videos = [
  {
    src: video1,
    alt: 'Video 1',
    // title: 'Sunset',
    // description: 'A beautiful sunset over the mountains.'
  },
  {
    src: video2,
    alt: 'Video 2',
    // title: 'Beach View',
    // description: 'Enjoying a relaxing day at the beach.'
  },
  {
    src: video3,
    alt: 'Video 3',
    // title: 'Cityscape',
    // description: 'A stunning view of the city skyline at night.'
  },
  {
    src: video4,
    alt: 'Video 4',
    // title: 'Waterfall',
    // description: 'Standing in awe of the majestic waterfall.'
  }
];

function Gallery() {
  const [show, setShow] = useState(false);
  const [currentVideo, setCurrentVideo] = useState({});

  const handleShow = (video) => {
    setCurrentVideo(video);
    setShow(true);
  };

  const handleClose = () => {
    setShow(false);
    setCurrentVideo({});
  };

  return (
    <div className="container mt-3">
      <div className="row">
        {videos.map((video, index) => (
          <div className="col-lg-4 col-md-6 mb-4" key={index}>
            <div className="card h-100">
              <video
                className="card-img-top"
                onClick={() => handleShow(video)}
                controls
                style={{ cursor: 'pointer' }}
              >
                <source src={video.src} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
              <div className="card-body">
                <h5 className="card-title">{video.title}</h5>
                <p className="card-text">{video.description}</p>
              </div>
            </div>
          </div>
        ))}
      </div>

      <Modal show={show} onHide={handleClose} centered size="lg">
        <Modal.Header closeButton>
          <Modal.Title>{currentVideo.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <video width="100%" controls autoPlay>
            <source src={currentVideo.src} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          <p>{currentVideo.description}</p>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default Gallery;
